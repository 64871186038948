import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import Backend from "i18next-http-backend";
// 语言包
import translation_zh from "./en-us.json";
import translation_en from "./zh-cn.json";
i18n
  // .use(Backend)
  .use(initReactI18next)
  .init({
    resources: {
      zh: {
        translation: translation_zh,
      },
      en: {
        translation: translation_en,
      },
    },
    lng: "zh",
    fallbackLng: "zh",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
