import React from "react";
import axios from "axios";
import type { AxiosInstance } from "axios";
import type { RequestConfig } from "./type";
import store from "@/store";
import {
  changeAddressAction,
  changeLoginStatusAction,
} from "@/store/modules/app";

// 当前正在请求的数量

class Request {
  instance: AxiosInstance;

  // request实例 => axios的实例
  constructor(config: RequestConfig) {
    this.instance = axios.create(config);
    // 每个instance实例都添加拦截器
    this.instance.interceptors.request.use(
      (config) => {
        const lang = localStorage.getItem("lang");
        let query = "zh-cn";
        if (lang) {
          query = lang;
        }
        config.url = `${config.url}?lang=${query}`;
        if (config && config.headers) {
          config.headers["Content-Type"] = "application/json";
          config.headers["User-Token"] =
            window.localStorage.getItem("token") || "";
          config.headers["app-key"] = "123456789";
          config.headers["User-Address"] =
            window.localStorage.getItem("address") || "";
        }
        // loading/token
        return config;
      },
      (err) => {
        return err;
      }
    );
    this.instance.interceptors.response.use(
      (res) => {
        return res.data;
      },
      (err) => {
        return err;
      }
    );

    // 针对特定的Request实例添加拦截器
    this.instance.interceptors.request.use(
      // @ts-ignore
      config.interceptors?.requestSuccessFn,
      config.interceptors?.requestFailureFn
    );
    this.instance.interceptors.response.use(
      config.interceptors?.responseSuccessFn,
      config.interceptors?.responseFailureFn
    );
  }

  // 封装网络请求的方法
  // T => IHomeData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request<T = any>(config: RequestConfig<T>) {
    // 单次请求的成功拦截处理
    if (config.interceptors?.requestSuccessFn) {
      config = config.interceptors.requestSuccessFn(config);
    }

    // 返回Promise
    return new Promise<T>((resolve, reject) => {
      this.instance
        .request<any, any>(config)
        .then((res) => {
          switch (res.code) {
            case 0:
              reject(res);
              break;
            case 1:
              resolve(res);
              break;
            case 2:
              reject(res);
              break;
            case -102:
              reject(res);
              break;
            case -403: {
              store.dispatch(changeLoginStatusAction(false));
              store.dispatch(changeAddressAction(""));
              window.localStorage.setItem("lang", "zh-cn");
              const url = window.location.hash;
              if (url.indexOf("?") != -1) {
                const str = url.substr(url.indexOf("?") + 1);
                window.location.hash = `/?${str}`;
              } else {
                window.location.hash = "/";
              }
              window.localStorage.clear();
              reject(res);
              break;
            }
            default:
              resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  get<T = any>(config: RequestConfig<T>) {
    return this.request({ ...config, method: "GET" });
  }

  post<T = any>(config: RequestConfig<T>) {
    return this.request({ ...config, method: "POST" });
  }

  delete<T = any>(config: RequestConfig<T>) {
    return this.request({ ...config, method: "DELETE" });
  }

  patch<T = any>(config: RequestConfig<T>) {
    return this.request({ ...config, method: "PATCH" });
  }
}

export default Request;
