import Request from "./http";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const request = new Request({
  baseURL: BASE_URL,
  timeout: 50000,
  interceptors: {
    requestSuccessFn: (config) => {
      return config;
    },
  },
});

export default request;
