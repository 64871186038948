import React, { memo, useState } from "react";
import styles from "./index.module.less";
import CircularProgress from "@mui/material/CircularProgress";
import { Mask } from "antd-mobile";

// const [visible, setVisible] = useState(false);
const LoadingPage = () => {
  return (
    <>
      <Mask visible={true}>
        <div className={styles.loading}>
          <CircularProgress />
        </div>
      </Mask>
    </>
  );
};

export default memo(LoadingPage);
