import request from "@/service/index";

// 登录
export function login(params: { sign?: string; referee?: string }) {
  return request.post({
    url: "/user/login/register",
    params,
  });
}

// 判断是否登录
export function isBind() {
  return request.post({
    url: "/user/user/isBind",
  });
}

// 绑定推荐关系
export function bindInviter(params: { address?: string }) {
  return request.post({
    url: "/user/user/bindInviter",
    params,
  });
}

// 收益总览
export function getBenefitsOverview() {
  return request.post({
    url: "/Invest/Invest_Pledge/overview",
  });
}

// 新闻列表
export function getNewList(params: { type_id: number }) {
  return request.post({
    url: "/news/news/index",
    params,
  });
}

// 新闻详情
export function getNewDetail(params: { id: number }) {
  return request.post({
    url: "/news/news/info",
    params,
  });
}

// 挖矿币种
export function getCurrentPrice() {
  return request.post({
    url: "/Invest/Invest_Pledge/getCoin",
  });
}

// 团队等级
export function getTeamLevelInfo() {
  return request.post({
    url: "/user/Team/index",
  });
}

// 团队列表
export function getTeamList(params: { page: number; limit: number }) {
  return request.post({
    url: "/user/Team/lists",
    params,
  });
}

//签到
export function checkIn() {
  return request.post({
    url: "/user/user/checkIn",
  });
}

// 公会列表
export function getBulletin() {
  return request.post({
    url: "/Invest/Job/index",
  });
}

// 公会申请
export function applyBulletin(params: { job_id: number }) {
  return request.post({
    url: "/Invest/Job/orderCreate",
    params: {
      job_id: params.job_id,
    },
  });
}

// 资产
export function getAssets() {
  return request.post({
    url: "/user/User/index",
  });
}

// 资产列表
export function getAssetsDetail(params: { page: number; limit: number }) {
  return request.post({
    url: "/user/user/money_log",
    params,
  });
}

// 冻结列表
export function getFreeze(params: { page: number; limit: number }) {
  return request.post({
    url: "/user/user/user_freeze_log",
    params,
  });
}

// 提现
export function withdraw(params: { money: number; money_type: number }) {
  return request.post({
    url: "/user/Withdraw/atm",
    params,
  });
}

// 质押页面信息
export function getPledgeInfo() {
  return request.post({
    url: "/Invest/Invest_Pledge/index",
  });
}

// 质押列表
export function getPledgeList(params: { page: number; limit: number }) {
  return request.post({
    url: "/Invest/Invest_Pledge/getOrderList",
    params,
  });
}
//质押余额
export function getPledgeAmount(params: { address: string }) {
  return request.post({
    url: "/api/index/balance",
    params,
  });
}

//获取MGY余额
export function getMgyAmount(params: { address: string; type: string }) {
  return request.post({
    url: "/api/index/balance",
    params,
  });
}

// 是否看完广告
export function getAdvertiseStatus() {
  return request.post({
    url: "/Invest/Invest_Pledge/isAd",
  });
}

// 输入质押金额换算BVT
export function exchangeBvt(params: { gode_money: number }) {
  return request.post({
    url: "/Invest/Invest_Pledge/trans",
    params,
  });
}

// 转换USDT
export function changeUsdt(params: { money: number }) {
  return request.post({
    url: "/Invest/Invest_Pledge/transAll",
    params,
  });
}

// 质押
export function pledge(params: {
  order_money: string;
  order_type: number;
  type: number;
  hash?: any;
  order_id?: string;
}) {
  return request.post({
    url: "/Invest/Invest_Pledge/orderCreate",
    params,
  });
}

// 开盲盒
export function openBox(params: { order_id?: any }) {
  return request.post({
    url: "/Invest/Invest_Pledge/multipleOrderMoney",
    params,
  });
}

// 订单查询
export function queryOrder(params: { order_id?: string }) {
  return request.post({
    url: "/Invest/Invest_Pledge/orderStatus",
    params,
  });
}

// 游戏页面
export function gamePage() {
  return request.post({
    url: "/Invest/Game/index",
  });
}

// 领取收益
export function receiveBenefits() {
  return request.post({
    url: "/Invest/Game/openBonus",
  });
}
//邀请
export function ShareInfo() {
  return request.post({
    url: "/user/Team/getShareInfo",
  });
}
//轮播
export function getSwiperList() {
  return request.post({
    url: "/news/news/getSwiper",
  });
}

//获取理财项目列表
export function getWealth() {
  return request.post({
    url: "/manage/money/index",
  });
}

//提交理财
export function addWealth(params: any) {
  return request.post({
    url: "/manage/money/investAdd",
    params,
  });
}

//获取已经投资的理财
export function getHistoryList(params: any) {
  return request.post({
    url: "/manage/money/investList",
    params,
  });
}

//获取已经投资的理财详情
export function getDetail(params: any) {
  return request.post({
    url: "/manage/money/investDetail",
    params,
  });
}

//获取收款钱包地址
export function getReceiving(params: any) {
  return request.post({
    url: "/user/recharge/config",
    params,
  });
}

//获取已经投资的理财详情
export function rechange(params: any) {
  return request.post({
    url: "/user/recharge/rechargeAdd",
    params,
  });
}
//获取已经投资的理财详情
export function rechangeQuery(params: any) {
  return request.post({
    url: "/user/recharge/rechargeStatus",
    params,
  });
}
