import React, { Suspense, useEffect } from "react";
import {
  useRoutes,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import LoadingPage from "@/components/LoadingPage";

import routes from "./router";
import { useAppDispatch } from "@/store";
import {
  appInit,
  changeInviteCodeAction,
  changeAddressAction,
} from "@/store/modules/app";

function App() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [search] = useSearchParams();
  const location = useLocation();
  useEffect(() => {
    dispatch(changeAddressAction(""));
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("address");
    setTimeout(() => {
      dispatch(appInit());
      dispatch(changeInviteCodeAction(search.get("inviteCode")));
    }, 2000);
  }, []);
  if (
    typeof window.ethereum !== "undefined" ||
    typeof window.web3 !== "undefined"
  ) {
    // Web3 browser user detected. You can now use the provider.
    const provider = window["ethereum"] || window.web3.currentProvider;
    provider.on("accountsChanged", async function (accounts: any) {
      window.localStorage.clear();
      // dispatch(changeLoginStatusAction(false));
      console.log(location.pathname, "pathname");
      if (location.pathname !== "/") {
        navigate("/");
      }
      window.location.reload();
      await dispatch(appInit());
    });
    provider.on("chainChanged", function (accounts: any) {
      window.location.reload();
      dispatch(appInit());
      dispatch(changeInviteCodeAction(search.get("inviteCode")));
    });
  }

  return (
    <div className={"container"}>
      <Toaster
        toastOptions={{
          className: "",
          style: {
            background: "rgba(0, 0, 0, 0.5)",
            color: "white",
          },
          duration: 1000,
        }}
        containerStyle={{
          top: 350,
        }}
      />
      <Suspense fallback={<LoadingPage />}>{useRoutes(routes)}</Suspense>
    </div>
  );
}

export default App;
