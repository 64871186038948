import React, { lazy } from "react";
import type { RouteObject } from "react-router-dom";

const Home = lazy(() => import("@/views/Home"));
const Pledge = lazy(() => import("@/views/Pledge"));
const Guild = lazy(() => import("@/views/Guild"));
const GuildDetail = lazy(() => import("@/views/Guild/detail"));
const Team = lazy(() => import("@/views/Team"));
const Assets = lazy(() => import("@/views/Assets"));
const AssetsFreeze = lazy(() => import("@/views/Assets/freeze"));
const Bulletin = lazy(() => import("@/views/Bulletin"));
const Invite = lazy(() => import("@/views/invite"));
const Money = lazy(() => import("@/views/Money"));
const MoneyDetail = lazy(() => import("@/views/Money/detail"));

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/pledge",
    element: <Pledge />,
  },
  {
    path: "/money",
    element: <Money />,
  },
  {
    path: "/money/detail",
    element: <MoneyDetail />,
  },
  {
    path: "/guild",
    element: <Guild />,
  },
  {
    path: "/guid/detail",
    element: <GuildDetail />,
  },
  {
    path: "/team",
    element: <Team />,
  },
  {
    path: "/assets",
    element: <Assets />,
  },
  {
    path: "/freeze",
    element: <AssetsFreeze />,
  },
  {
    path: "/bulletin",
    element: <Bulletin />,
  },
  {
    path: "/invite",
    element: <Invite />,
  },
];

export default routes;
