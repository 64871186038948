import React from "react";
import ReactDOM from "react-dom/client";
import i18n from "@/language/index";
import "./language/index"; //引用配置文件

// 重置样式
import "normalize.css";
import "@/assets/style/variable.less";
import "@/assets/style/index.less";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);

reportWebVitals();
