import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "@/service/api";
import { connectWeb3, web3, readContract } from "@/web3";
import ABI from "@/config/contract/abi.json";
import { getQueryString } from "@/utils";

interface IState {
  token: string;
  address: string;
  loginStatus: boolean;
  validCode: boolean;
  mainAmount: string;
  tokenAmount: string;
  inviteCode: string;
  lang: string;
}

const initialState: IState = {
  token: "",
  address: "",
  loginStatus: false,
  validCode: true,
  mainAmount: "",
  tokenAmount: "",
  inviteCode: "",
  lang: "en",
};
export const appInit = createAsyncThunk(
  "fetchLogin",
  async (_, { dispatch }) => {
    const account = await connectWeb3();
    // @ts-ignore
    const address = account.toString();
    window.localStorage.setItem("address", address);
    const inviteCode = getQueryString("inviteCode");
    api
      .login({
        // sign,
        referee: inviteCode,
      })
      .then(async (res: any) => {
        if (res.code > 0) {
          window.localStorage.setItem("token", res.data.token);
          window.localStorage.setItem("address", address);

          dispatch(changeAddressAction(address));
          dispatch(changeTokenAction(res.data.token));
          dispatch(changeLoginStatusAction(true));
          const mainAmount = await web3.eth.getBalance(address);
          // @ts-ignore
          const tokenAmount = await readContract({
            abi: ABI,
            walletAddress: address,
            contract: "0x7d644f056fE8B7caB4Be2f8AB5E71A8a67BD97cC",
            decimal: true,
            method: "balanceOf",
            config: [address],
          });
          dispatch(changeTokenAmountAction(tokenAmount));
          dispatch(
            changeMainAmountAction(web3.utils.fromWei(mainAmount, "ether"))
          );
          return res;
        } else {
          if (res.code == -10086) {
            window.localStorage.setItem("address", address);
            dispatch(changeAddressAction(address));
            dispatch(changeValidCodeAction(false));
          }
        }
      });
  }
);

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changeTokenAction: (state, { payload }) => {
      state.token = payload;
    },
    changeAddressAction: (state, { payload }) => {
      state.address = payload;
    },
    changeValidCodeAction: (state, { payload }) => {
      state.validCode = payload;
    },
    changeLoginStatusAction: (state, { payload }) => {
      state.loginStatus = payload;
    },
    changeMainAmountAction: (state, { payload }) => {
      state.mainAmount = payload;
    },
    changeTokenAmountAction: (state, { payload }) => {
      state.tokenAmount = payload;
    },
    changeInviteCodeAction: (state, { payload }) => {
      state.inviteCode = payload;
    },
    changeLangAction: (state, { payload }) => {
      state.lang = payload;
    },
  },
});

export const {
  changeTokenAction,
  changeAddressAction,
  changeLoginStatusAction,
  changeValidCodeAction,
  changeMainAmountAction,
  changeTokenAmountAction,
  changeInviteCodeAction,
  changeLangAction,
} = appSlice.actions;
export default appSlice.reducer;
