import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "@/service/api";
import { signer, connectWallet } from "@/web3";
interface IState {
  benefitsOverview: {
    zhitui_bonus?: number; //直推奖励
    team_bonus?: number; //共识奖励
    bonus?: number; //共识分红
  };
  newList: any;
  newDetail: any;
  currentPrice: any;
}

const initialState: IState = {
  benefitsOverview: {}, // 收益总览
  newList: [], // 新闻列表
  newDetail: {}, // 新闻详情
  currentPrice: {},
};

export const fetchHome = createAsyncThunk("fetchHome", (_, { dispatch }) => {
  api.getBenefitsOverview().then((res) => {
    dispatch(changeBenefitsOverviewAction(res.data));
  });
  api.getNewList({ type_id: 1 }).then((res) => {
    dispatch(changeNewListAction(res.data));
  });
  api.getCurrentPrice().then((res) => {
    dispatch(changeCurrentPrice(res.data));
  });
});

export const fetchNewDetail = createAsyncThunk(
  "fetchNewDetail",
  (_, { dispatch }) => {
    api.getNewDetail({ id: 1 }).then((res) => {
      dispatch(changeNewDetailAction(res.data));
    });
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    changeBenefitsOverviewAction: (state, { payload }) => {
      state.benefitsOverview = payload;
    },
    changeNewListAction: (state, { payload }) => {
      state.newList = payload;
    },
    changeNewDetailAction: (state, { payload }) => {
      state.newDetail = payload;
    },
    changeCurrentPrice: (state, { payload }) => {
      state.currentPrice = payload;
    },
  },
});

export const {
  changeBenefitsOverviewAction,
  changeNewListAction,
  changeNewDetailAction,
  changeCurrentPrice,
} = homeSlice.actions;
export default homeSlice.reducer;
