import Web3 from "web3";
import * as types from "@/types/web3.types";

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME;
const CHAIN_SYMBOL = process.env.REACT_APP_CHAIN_SYMBOL;
const CHAIN_DECIMALS = process.env.REACT_APP_CHAIN_DECIMALS;
const RPC_URL = process.env.REACT_APP_RPC_URL;
const BLOCK_EXPLORER_URLS = process.env.REACT_APP_BLOCK_EXPLORER_URLS;
// web3 ts的类型定义
// 检查是否是新的MetaMask 或 DApp浏览器
let web3Provider;

async function init() {
  if (window.ethereum) {
    web3Provider = window.ethereum;
    try {
      // 请求用户授权
      await window.ethereum.enable();
    } catch (error) {
      // 用户不授权时
      // console.error('User denied account access')
    }
  } else if (window.web3) {
    // 老版 MetaMask Legacy dapp browsers...
    web3Provider = window.web3.currentProvider;
  } else {
    web3Provider = new Web3.providers.HttpProvider("http://localhost:8545");
  }
}

init();
// @ts-ignore
const web3 = new Web3(web3Provider);

// 链接钱包
async function connectWallet() {
  return new Promise((resolve, reject) => {
    window?.ethereum
      ?.request({
        method: "eth_requestAccounts",
      })
      .then((accounts: string[]) => {
        console.log("获取钱包", accounts);
        if (accounts && accounts[0]) {
          resolve(accounts[0].toString());
        } else {
          reject();
        }
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

// 获取区块信息
function getTransaction(transactionHash: string) {
  return new Promise((resolve, reject) => {
    web3.eth.getTransactionReceipt(transactionHash, function (error, result) {
      if (!error) {
        resolve(result);
      } else {
        reject(error);
      }
    });
  });
}

// 签名
async function signer(message: string, address: string): Promise<string> {
  return await web3.eth.personal.sign(
    web3.utils.toHex(message),
    address,
    "password"
  );
}

function readContract(obj: types.readContractType) {
  const { config } = obj;
  // 创建合约对象
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    // console.log(await new web3.eth.Contract(JSON.parse(obj.abi), obj.contract, obj.options).methods)
    await new web3.eth.Contract(
      // @ts-ignore
      obj.abi,
      obj.contract,
      obj.options
      // @ts-ignore
    ).methods[obj.method](...Array.from(config)).call(
      (error: never, result: string) => {
        if (!error) {
          if (obj.decimal) {
            resolve(Web3.utils.fromWei(result, "ether"));
          } else {
            resolve(result);
          }
        } else {
          reject(error);
        }
      }
    );
  });
}

async function writeContract(obj: types.writeContractType) {
  const { config } = obj;
  // 创建合约对象
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    console.log(config);
    // console.log(await new web3.eth.Contract(JSON.parse(obj.abi), obj.contract, obj.options).methods)
    await new web3.eth.Contract(
      // @ts-ignore
      obj.abi,
      obj.contract,
      obj.options
      // @ts-ignore
    ).methods[obj.method](...Array.from(config)).send(
      {
        from: obj.walletAddress,
      },
      (error: never, transactionHash: string) => {
        if (!error) {
          // 成功执行，返回交易号
          resolve(transactionHash);
        } else {
          // 失败执行
          reject(error);
        }
      }
    );
  });
}

async function connectWeb3() {
  // toast(window.ethereum.request + "12312313");
  window.ethereum
    .request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: Web3.utils.numberToHex(5500), //链id
        },
      ],
    })
    .then((ele: any) => {
      console.log(ele);
    })
    .catch((err: any) => {
      if (err.code == 4902) {
        window.ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: Web3.utils.numberToHex(5500), //链id
                rpcUrls: ["https://rpc.godechain03.com"],
                chainName: "GODE",
                blockExplorerUrls: ["https://explorer.godechain.com"],
                nativeCurrency: {
                  name: "GODE",
                  symbol: "GODE",
                  decimals: 18,
                },
              },
            ],
          })
          .then(() => {
            return connectWallet();
            // this.changeChain();
          });
        if (err.code == 4902) {
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.numberToHex(5500), //链id
                  rpcUrls: ["https://rpc.godechain03.com"],
                  chainName: "GODE",
                  blockExplorerUrls: ["https://explorertest.godechain.com"],
                  nativeCurrency: {
                    name: "GODE",
                    symbol: "GODE",
                    decimals: 18,
                  },
                },
              ],
            })
            .then(() => {
              // this.changeChain();
            });
        }
      } else if (err.code === 4001) {
        return;
      }
    });
  return await connectWallet();
}
async function mainCurrencyTransaction(obj: types.mainCurrencyTransactionType) {
  return new Promise((resolve, reject) => {
    window?.ethereum
      ?.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: obj.from, // 付款方
            to: obj.to, // 收款方
            value: Web3.utils.toHex(Web3.utils.toWei(obj.value, "ether")), // 价格 16进制
            // gasPrice: '0x'+'0',	// 手续费 可以不设置但是不能过低
            // gasLimit: '0x'+'5208',	// 暂时不知道是什么东西
            // gas: '0x'+'33450'	// 手续费 同上
          },
        ],
      })
      .then((transactionHash: string) => {
        // 成功执行
        resolve(transactionHash);
      })
      .catch(() => {
        // 失败执行
        reject("交易取消");
      });
  });
}

//
// REACT_APP_CHAIN_ID=5500
// REACT_APP_CHAIN_NAME=GODE
// REACT_APP_CHAIN_SYMBOL=GODE
// REACT_APP_CHAIN_DECIMALS=18
// REACT_APP_RPC_URL=https://rpc.godechain03.com
// REACT_APP_BLOCK_EXPLORER_URLS=https://explorer.godechain.com

function network() {
  window.ethereum
    .request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          // @ts-ignore
          chainId: Web3.utils.numberToHex(5500), //链id
        },
      ],
    })
    .then((ele: any) => {
      console.log(ele);
    })
    .catch((err: { code: number }) => {
      if (err.code == 4902) {
        window.ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [
              {
                // @ts-ignore
                chainId: Web3.utils.numberToHex(5500), //链id
                rpcUrls: ["https://rpc.godechain03.com"],
                chainName: "GODE",
                blockExplorerUrls: ["https://explorer.godechain.com"],
                nativeCurrency: {
                  name: "GODE",
                  symbol: "GODE",
                  decimals: 18,
                },
              },
            ],
          })
          .then(async () => {
            console.log("网络切换");
            // 链接钱包
            // return await connectWallet();
          });
      } else if (err.code === 4001) {
        return;
      }
    });
}

export {
  Web3,
  web3,
  network,
  getTransaction,
  readContract,
  writeContract,
  connectWallet,
  connectWeb3,
  mainCurrencyTransaction,
  signer,
};
